const getLocaleFromTimeZone = (timeZone: string | undefined): string => {
  const timeZoneLocaleMap: Record<string, string> = {
    // Ucrania
    "Europe/Kyiv": "uk-UA",
    // Argentina
    "America/Argentina/Buenos_Aires": "es-AR",
    "America/Buenos_Aires": "es-AR",
    // Russia
    "Europe/Moscow": "ru-RU",
    "Asia/Yekaterinburg": "ru-RU",
    "Asia/Vladivostok": "ru-RU",
    // Poland
    "Europe/Warsaw": "pl-PL",
    // Georgia
    "Asia/Tbilisi": "ka-GE",
    // USA
    "America/New_York": "en-US", // Eastern Time
    "America/Chicago": "en-US", // Central Time
    "America/Denver": "en-US", // Mountain Time
    "America/Los_Angeles": "en-US", // Pacific Time
  }

  return (timeZone && timeZoneLocaleMap[timeZone]) || "en-US" // By default: "en-US"
}

export const formatTimestampToDate = (timestamp?: string): string => {
  const dateParts = timestamp?.split(" ")
  const time = dateParts?.[0]

  if (time) {
    const date = new Date(time)
    const timeZone = dateParts?.[1]
    const locale = getLocaleFromTimeZone(timeZone)

    const day = date.toLocaleString(locale, { timeZone, day: "numeric" })
    const monthLocale = locale.split("-")
    monthLocale[0] = "en" //Ensure month is in English no matter the locale
    const month = date.toLocaleString(monthLocale.join("-"), {
      timeZone,
      month: "short",
    })
    const horas = date
      .toLocaleString(locale, {
        timeZone,
        timeZoneName: "short",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .replace("a. m.", "AM")
      .replace("p. m.", "PM")

    return `${day} ${month} at ${horas}`
  }
  return ""
}

export type ISOString = string // "2023-11-07T12:06:17.676Z"
