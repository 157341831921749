import { Location } from "@app/model"

export type APITopLocation = {
  location: string
  selected: number
  previous: number
  change: number
}

export const APITopLocationsToModel = (
  topLocations: APITopLocation[],
): Location.Location[] => {
  return topLocations.map(({ location, selected, previous, change }) => {
    return {
      location,
      selected,
      previous,
      change,
    }
  })
}
