import * as NetworkError from "@app/api/networkError"
import { ParseError } from "@effect/schema/ParseResult"
import * as S from "@effect/schema/Schema"
import { Either, pipe } from "effect"

import { fetchWithoutAuth } from "../api"

export type CheckEmailAvailabilityError =
  | NetworkError.NetworkError
  | NetworkError.UnexpectedError
  | ParseError

type EmailAvailable = { available: boolean }

const EmailAvailableSchema = S.struct({
  available: S.boolean,
})

type QueryParams = {
  email: string
}

type Result = Either.Either<EmailAvailable, CheckEmailAvailabilityError>

type CheckEmailAvailabilityParams = {
  email: string
}
export const checkEmailAvailability = async ({
  email,
}: CheckEmailAvailabilityParams): Promise<Result> => {
  const queryParams: QueryParams = {
    email,
  }

  const params = new URLSearchParams(queryParams).toString()
  const path = `sts/api/v2/account/check-email-availability?${params}`
  const response = await fetchWithoutAuth({
    method: "GET",
    path,
  })

  if (!response.ok) {
    const result = (await response.json()) as unknown

    if (NetworkError.isAPINetworkError(result)) {
      const error = NetworkError.toNetworkError(response.status, result)
      return Either.left(error)
    } else {
      const error = new NetworkError.UnexpectedError(`${result}`)
      return Either.left(error)
    }
  }

  const result = (await response.json()) as unknown

  return pipe(result, S.decodeUnknownEither(EmailAvailableSchema))
}
