import * as NetworkError from "@app/api/networkError"
import { ActiveUsers } from "@app/model"
import { Either } from "effect"

import { fetchWithoutAuth } from "../api"

import * as APIActiveUsers from "./apiActiveUsers"

export type GetActiveUsersError =
  | NetworkError.NetworkError
  | NetworkError.UnexpectedError

type Result = Either.Either<ActiveUsers.ActiveUsers, GetActiveUsersError>

type GetActiveUsersParams = {
  interval?: string
}

export const getActiveUsers = async (
  interval: string = "1y",
): Promise<Result> => {
  const queryParams: GetActiveUsersParams = {
    interval,
  }

  const params = new URLSearchParams(queryParams).toString()
  const path = `sts/api/v2/dashboard/active-users?${params}`
  const response = await fetchWithoutAuth({
    method: "GET",
    path,
  })

  if (!response.ok) {
    const result = (await response.json()) as unknown

    if (NetworkError.isAPINetworkError(result)) {
      const error = NetworkError.toNetworkError(response.status, result)
      return Either.left(error)
    } else {
      const error = new NetworkError.UnexpectedError(`${result}`)
      return Either.left(error)
    }
  }

  const result = (await response.json()) as APIActiveUsers.APIActiveUsers

  return Either.right(APIActiveUsers.APIActiveUsersToModel(result))
}
