import React from "react"
import { useTranslation } from "react-i18next"
import { DateFilterTab } from "@app/model"
import { DashboardTab } from "@app/model/dashboardTab"

import * as UIKit from "uiKit"

import { useActiveTabContext } from "../ActiveTabContext"
import { useDateFilterContext } from "../DateFilterContext"

type DateFilterTabsProps = {
  tabListClassName: string
  selectedTabClassName: string
  selectedTabChildClassName?: string
}

const DateFilterTabs = ({
  tabListClassName,
  selectedTabClassName,
  selectedTabChildClassName,
}: DateFilterTabsProps): JSX.Element => {
  const { t } = useTranslation()
  const { activeTab } = useActiveTabContext()
  const { dateFilter, updateDateFilter } = useDateFilterContext()

  const handleSetTabIndex = (index: number): void => {
    updateDateFilter(index as DateFilterTab.DateFilterTab)
  }

  const canShowTab = (tab: DateFilterTab.DateFilterTab): boolean =>
    // activeTab === DashboardTab.PerformanceAnalytics ||
    tab !== DateFilterTab.DateFilterTab.ThisYear

  const toUIKitTab = (tab: DateFilterTab.DateFilterTab): UIKit.Tab => {
    switch (tab) {
      case DateFilterTab.DateFilterTab.ThisYear:
        return {
          title: t("header.tabs.date.this_year"),
          providedIndex: DateFilterTab.DateFilterTab.ThisYear,
        }
      case DateFilterTab.DateFilterTab.Last30Days:
        return {
          title: t("header.tabs.date.last_30_days"),
          providedIndex: DateFilterTab.DateFilterTab.Last30Days,
        }
      case DateFilterTab.DateFilterTab.Last7Days:
        return {
          title: t("header.tabs.date.last_7_days"),
          providedIndex: DateFilterTab.DateFilterTab.Last7Days,
        }
      case DateFilterTab.DateFilterTab.Last24Hours:
        return {
          title: t("header.tabs.date.last_24_hours"),
          providedIndex: DateFilterTab.DateFilterTab.Last24Hours,
        }
    }
  }

  const tabsToShow = DateFilterTab.allTabs.filter(canShowTab).map(toUIKitTab)

  const getTabIndex = (): number => {
    if (
      activeTab === DashboardTab.ActionableData &&
      dateFilter !== DateFilterTab.DateFilterTab.ThisYear
    ) {
      return dateFilter - 1
    } else {
      return dateFilter
    }
  }

  return (
    <UIKit.Tabs
      tabs={tabsToShow}
      selectedIndex={getTabIndex()}
      onTabChange={handleSetTabIndex}
      tabClassName="py-2 px-3"
      tabListClassName={tabListClassName}
      tabTextClassName="text-xs"
      selectedTabClassName={selectedTabClassName}
      unselectedTabClassName="bg-zinc-800"
      selectedTabChildClassName={selectedTabChildClassName}
    />
  )
}

export default DateFilterTabs
