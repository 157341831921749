import React from "react"
import { useTranslation } from "react-i18next"
import { DashboardTab } from "@app/model"

import { Icons } from "assets"
import * as UIKit from "uiKit"

import { useActiveTabContext } from "../ActiveTabContext"

type DashboardTabsProps = {
  tabClassName: string
  tabListClassName: string
  selectedTabClassName?: string
  unselectedTabClassName?: string
  selectedTabChildClassName?: string
}
const DashboardTabs = ({
  tabClassName,
  tabListClassName,
  selectedTabClassName,
  unselectedTabClassName,
  selectedTabChildClassName,
}: DashboardTabsProps): JSX.Element => {
  const { t } = useTranslation()

  // const PerformanceAnalytics = DashboardTab.DashboardTab.PerformanceAnalytics
  const ActionableData = DashboardTab.DashboardTab.ActionableData

  const { activeTab, updateActiveTab } = useActiveTabContext()

  const handleSetTabIndex = (index: number): void => {
    updateActiveTab(index as DashboardTab.DashboardTab)
  }

  const iconClassName = "w-8 h-8"
  const tabTextClassName = "ml-3 text-lg"
  const tabChildClassName = "w-8 h-8 items-center justify-center"

  const toUIKitTab = (tab: DashboardTab.DashboardTab): UIKit.Tab => {
    switch (tab) {
      // case PerformanceAnalytics:
      //   return {
      //     title: DashboardTab.toTranslation(t, tab),
      //     children: <Icons.BarChart className={iconClassName} />,
      //     dataTestId: "performance-analytics-tab",
      //   }
      case ActionableData:
        return {
          title: DashboardTab.toTranslation(t, tab),
          children: <Icons.SquareBox className={iconClassName} />,
          dataTestId: "actionable-data-tab",
        }
    }
  }

  const tabsToShow = DashboardTab.allTabs.map(toUIKitTab)

  return (
    <UIKit.Tabs
      tabs={tabsToShow}
      selectedIndex={activeTab}
      onTabChange={handleSetTabIndex}
      tabListClassName={tabListClassName}
      tabClassName={tabClassName}
      tabTextClassName={tabTextClassName}
      tabChildClassName={tabChildClassName}
      selectedTabClassName={selectedTabClassName}
      unselectedTabClassName={unselectedTabClassName}
      selectedTabChildClassName={selectedTabChildClassName}
    />
  )
}

export default DashboardTabs
