export const currentUser: ["currentUser"] = ["currentUser"]

export const recentReports = (
  period: string,
  page: number,
): ["recentReports", { period: string; page: number }] => {
  return ["recentReports", { period, page }]
}

export const topIncidentTypes = (
  period: string,
): ["topIncidentTypes", { period: string }] => {
  return ["topIncidentTypes", { period }]
}

export const topLocations = (
  period: string,
): ["topLocations", { period: string }] => {
  return ["topLocations", { period }]
}

export const QueryKey = {
  currentUser,
  recentReports,
  topIncidentTypes,
  topLocations,
}
