import { ReactNode } from "react"
import { Tab } from "@headlessui/react"
import cn from "classnames"
import { pipe, ReadonlyArray } from "effect"

export type Tab = {
  title: string
  children?: ReactNode
  dataTestId?: string
  providedIndex?: number
}

type TabsProps = {
  tabs: Tab[]
  selectedIndex?: number
  onTabChange?: (index: number) => void
  tabListClassName?: string
  tabTextClassName: string
  tabChildClassName?: string
  tabClassName?: string
  selectedTabClassName?: string
  unselectedTabClassName?: string
  selectedTabChildClassName?: string
}
const Tabs = ({
  tabs,
  selectedIndex,
  onTabChange,
  tabListClassName,
  tabChildClassName,
  tabTextClassName,
  tabClassName,
  selectedTabClassName,
  unselectedTabClassName,
  selectedTabChildClassName,
}: TabsProps): JSX.Element => {
  if (!tabs) {
    return <></>
  }

  const handleTabSelect = (index: number, providedIndex?: number): void => {
    const newIndex = providedIndex ? providedIndex : index
    if (onTabChange) {
      onTabChange(newIndex)
    }
  }

  const determineChildClassName = (
    index: number,
    className?: string,
  ): string => {
    return cn(
      className,
      selectedIndex === index
        ? selectedTabChildClassName
        : "stroke-emerald-100",
    )
  }

  const tabElements = pipe(
    tabs,
    ReadonlyArray.map(
      ({ title, children, dataTestId, providedIndex }, index) => (
        <Tab
          className={({ selected }) =>
            cn(
              "row-button",
              tabClassName,
              selected ? selectedTabClassName : unselectedTabClassName,
            )
          }
          key={title}
          onClick={() => handleTabSelect(index, providedIndex)}
          data-testid={dataTestId}
        >
          <span className={determineChildClassName(index, tabChildClassName)}>
            {children}
          </span>
          <span className={determineChildClassName(index, tabTextClassName)}>
            {title}
          </span>
        </Tab>
      ),
    ),
  )

  const tabListClass = cn("flex justify-center", tabListClassName)

  return (
    <div>
      <Tab.Group selectedIndex={selectedIndex}>
        <Tab.List className={tabListClass}>{tabElements}</Tab.List>
      </Tab.Group>
    </div>
  )
}

export default Tabs
