import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import * as ActiveUsersAPI from "@app/api/performanceAnalytics/getActiveUsers"
import * as ErrorHandler from "@app/errorHandler"
import { Either, pipe } from "effect"

const TotalUsers = (): JSX.Element => {
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0)
  const { t } = useTranslation()

  useEffect(() => {
    const getTotalUsers = async (): Promise<void> => {
      const result = await ActiveUsersAPI.getActiveUsers()
      pipe(
        result,
        Either.match({
          onLeft: error => {
            ErrorHandler.captureException(error)
          },
          onRight: response => {
            setTotalUsersCount(response.totalUsers)
          },
        }),
      )
    }
    void getTotalUsers()
  }, [t])

  return (
    <div className="flex lg:w-full min-h-20 h-20 px-6 py-8 rounded-3xl border border-zinc-600 border-solid flex-col justify-center">
      <div className="flex w-full justify-start items-start pb-3 sm:pb-4">
        <span className="visualization-title">
          {t("performance_analytics.total_users")}
        </span>
      </div>
      <div className="flex flex-row w-full h-full justify-between">
        <div className="flex items-end">
          <span className="text-4xl sm:text-5xl font-medium leading-0">
            {totalUsersCount}
          </span>
        </div>
        <div className="flex items-end">
          <span>/{t("performance_analytics.all_time")}</span>
        </div>
      </div>
    </div>
  )
}

export default TotalUsers
