import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import * as TopLocationsAPI from "@app/api/actionableData/getTopLocations"
import { useDateFilterContext } from "@app/App/DateFilterContext"
import { useScreenSizeContext } from "@app/App/ScreenSizeContext"
import * as ErrorHandler from "@app/errorHandler"
import { DateFilterTab, Location } from "@app/model"
import HorizontalBarChart from "@app/uiKit/HorizontalBarChart"
import { Either, pipe } from "effect"

import BarChartLegend from "./BarChartLegend"
import ErrorMessage from "./ErrorMessage"
import NoDataAvailable from "./NoDataAvailable"
import PercentChange from "./PercentChange"

const TopLocations = (): JSX.Element => {
  const { t } = useTranslation()
  const [locations, setLocations] = useState<Location.Location[]>([])
  const [hasError, setHasError] = useState(false)
  const { getAPIDateFilter } = useDateFilterContext()
  const { isSmallScreenSize } = useScreenSizeContext()
  const dateInterval = getAPIDateFilter()

  const notFilteredToLast24Hours =
    dateInterval !==
    DateFilterTab.APIDateFilters[DateFilterTab.DateFilterTab.Last24Hours]

  const {
    data,
    error: apiError,
    isLoading,
  } = TopLocationsAPI.useGetTopLocationsQuery(dateInterval)

  useEffect(() => {
    if (data) {
      pipe(
        data,
        Either.match({
          onLeft: error => {
            setHasError(true)
            ErrorHandler.captureException(error)
          },
          onRight: response => {
            setLocations(response)
          },
        }),
      )
    }
  }, [data, isLoading])

  useEffect(() => {
    if (apiError) {
      setHasError(true)
      ErrorHandler.captureException(apiError)
    }
  }, [apiError])

  const sortedLocations = locations.sort((a, b) => {
    if (b.selected !== a.selected) {
      return b.selected - a.selected
    }
    return a.location.localeCompare(b.location)
  })

  const hasNoData = locations.every(
    item => item.selected === 0 && item.previous === 0,
  )

  const showLegend = !isLoading && !hasError && !hasNoData && !isSmallScreenSize

  return (
    <div className="flex flex-col h-[50%] min-h-60 sm:min-h-72 px-5 pb-2 pt-6 sm:p-6 visualization">
      <div className="flex flex-row justify-between">
        <span className="visualization-title">
          {t("actionable_data.top_locations")}
        </span>
        {showLegend && <BarChartLegend />}
      </div>
      {!hasNoData ? (
        <div className="flex flex-row h-full mr-4">
          <HorizontalBarChart
            yAxisData={sortedLocations.map(
              loc => `${loc.location.split(" ").join("\n")} (${loc.selected})`,
            )}
            selectedData={sortedLocations.map(loc => loc.selected)}
            previousData={sortedLocations.map(loc => loc.previous)}
            fullSizeBars={!notFilteredToLast24Hours}
          />
          {notFilteredToLast24Hours && (
            <PercentChange items={sortedLocations} />
          )}
        </div>
      ) : (
        !isLoading &&
        (hasError ? <ErrorMessage isWideComponent /> : <NoDataAvailable />)
      )}
    </div>
  )
}

export default TopLocations
