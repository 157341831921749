import React from "react"
import { useTranslation } from "react-i18next"
import { DashboardTab } from "@app/model"

import { Icons } from "assets"

import { useActiveTabContext } from "../ActiveTabContext"
import { useScreenSizeContext } from "../ScreenSizeContext"

import DashboardTabs from "./DashboardTabs"
import DateFilterTabs from "./DateFilterTabs"
import HamburgerDrawer from "./HamburgerDrawer"
import ProfileHeader from "./ProfileHeader"

const Header = (): JSX.Element => {
  const { activeTab } = useActiveTabContext()
  const { isMediumScreenSize } = useScreenSizeContext()
  const { t } = useTranslation()

  const containerClassName =
    "flex flex-row h-16 items-center px-5 py-7 lg:px-10"

  return (
    <div className={containerClassName}>
      {isMediumScreenSize ? (
        <>
          <HamburgerDrawer />
          <span className="text-lg font-medium items-center justify-center">
            {DashboardTab.toTranslation(t, activeTab)}
          </span>
          <div className="flex flex-row items-center ml-auto">
            <ProfileHeader />
          </div>
        </>
      ) : (
        <>
          <div data-testid="logo-container" className="mr-6">
            <Icons.Logo />
          </div>
          <div className="mr-6">
            <Icons.VerticalLine />
          </div>
          <DashboardTabs
            tabClassName="px-4"
            tabListClassName="flex-row mr-6 items-center"
            selectedTabClassName="rounded-full bg-zinc-600 py-4 stroke-emerald-100"
            unselectedTabClassName="bg-slate-900"
          />
          <DateFilterTabs
            tabListClassName="flex-row mr-6 bg-zinc-800 rounded-2xl bdr-zinc-600 p-4 items-center"
            selectedTabClassName="rounded-full bg-zinc-600"
          />
          <div className="ml-auto">
            <ProfileHeader />
          </div>
        </>
      )}
    </div>
  )
}

export default Header
