import * as NetworkError from "@app/api/networkError"
import { IncidentType } from "@app/model"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { Either } from "effect"

import { buildAuthHeader, fetchWithAuth } from "../api"
import { QueryKey } from "../queryKey"

import * as APITopIncidentType from "./apiTopIncidentTypes"

export type GetTopIncidentTypesError =
  | NetworkError.NetworkError
  | NetworkError.UnexpectedError

type Result = Either.Either<
  IncidentType.IncidentType[],
  GetTopIncidentTypesError
>

type GetTopIncidentTypesParams = {
  period?: string
}

export const getTopIncidentTypes = async (
  period: string = "30d",
  token: string,
): Promise<Result> => {
  const queryParams: GetTopIncidentTypesParams = {
    period,
  }

  const params = new URLSearchParams(queryParams).toString()
  const path = `report/api/v2/stats/incidents?${params}`

  const response = await fetchWithAuth({
    method: "GET",
    path,
    authHeader: buildAuthHeader(token),
  })

  if (!response.ok) {
    const result = (await response.json()) as unknown

    if (NetworkError.isAPINetworkError(result)) {
      const error = NetworkError.toNetworkError(response.status, result)
      return Either.left(error)
    } else {
      const error = new NetworkError.UnexpectedError(`${result}`)
      return Either.left(error)
    }
  }

  const result = (await response.json()) as {
    incidents: APITopIncidentType.APITopIncidentType[]
  }

  return Either.right(
    APITopIncidentType.APITopIncidentTypesToModel(result.incidents),
  )
}

export const useGetTopIncidentTypesQuery = (
  period: string = "30d",
): UseQueryResult<Result, Error> => {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery<Result, Error>({
    queryKey: QueryKey.topIncidentTypes(period),
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      return getTopIncidentTypes(period, token)
    },
  })
}
