import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import * as CurrentUserAPI from "@app/api/currentUser/getCurrentUser"
import * as CurrentUser from "@app/model/currentUser"
import * as EmergencyContact from "@app/model/emergencyContact"

type UserProfileContextState = {
  userProfile: CurrentUser.CurrentUser
  updatePersonalInformation: (
    firstName: string,
    lastName: string,
    zipCode: string,
    phoneNumber: string,
  ) => void
  updateEmergencyContact: (
    emergencyContact: EmergencyContact.EmergencyContact,
  ) => void
  userReady: boolean
}

const UserProfileContext = createContext<UserProfileContextState | undefined>(
  undefined,
)

type UserProfileProviderProps = {
  children: ReactNode
}

export const UserProfileProvider = ({
  children,
}: UserProfileProviderProps): JSX.Element => {
  const [userProfile, setUserProfile] = useState<CurrentUser.CurrentUser>({
    id: "",
    email: "",
    loginCount: 0,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    lastLoginAt: "",
    createdAt: "",
    updatedAt: "",
    zipCode: "",
    emergencyContact: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      relationship: "",
    },
  })
  const { data: currentUser } = CurrentUserAPI.useGetCurrentUserQuery()

  useEffect(() => {
    if (currentUser) {
      setUserProfile(currentUser)
    }
  }, [currentUser])

  const updatePersonalInformation = (
    firstName: string,
    lastName: string,
    zipCode: string,
    phoneNumber: string,
  ): void => {
    setUserProfile(prevUserProfile => ({
      ...prevUserProfile,
      firstName,
      lastName,
      zipCode,
      phoneNumber,
    }))
  }

  const updateEmergencyContact = (
    emergencyContact: EmergencyContact.EmergencyContact,
  ): void => {
    setUserProfile(prevUserProfile => ({
      ...prevUserProfile,
      emergencyContact,
    }))
  }

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
        updatePersonalInformation,
        updateEmergencyContact,
        userReady: userProfile.id !== "",
      }}
    >
      {children}
    </UserProfileContext.Provider>
  )
}

export const useUserProfileContext = (): UserProfileContextState => {
  const context = useContext(UserProfileContext)

  if (context === undefined) {
    throw new Error("UserProfileContext must be used with a Provider")
  }

  return context
}
