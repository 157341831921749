import { TFunction } from "i18next"

export enum DashboardTab {
  // PerformanceAnalytics = 0,
  ActionableData = 0,
}

export const DashboardTabNames = {
  // [DashboardTab.PerformanceAnalytics]: "PerformanceAnalytics",
  [DashboardTab.ActionableData]: "ActionableData",
}

export const allTabs: DashboardTab[] = [
  // DashboardTab.PerformanceAnalytics,
  DashboardTab.ActionableData,
]

export const toTranslation = (t: TFunction, tab: DashboardTab): string => {
  switch (tab) {
    // case DashboardTab.PerformanceAnalytics:
    //   return t("header.tabs.performance_analytics")
    case DashboardTab.ActionableData:
      return t("header.tabs.actionable_data")
  }
}
