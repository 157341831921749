import React, { ReactNode } from "react"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import { styled } from "@mui/system"

import { Icons } from "assets"

type InfoTooltipProps = {
  text: ReactNode | string
}

const InfoTooltip = ({ text }: InfoTooltipProps): JSX.Element => {
  const tooltipText = (
    <span className="font-sans text-base text-stone-200 font-light">
      {text}
    </span>
  )
  const StyledTooltip = styled<typeof Tooltip>(({ className, ...props }) => (
    <Tooltip
      {...props}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      classes={{ popper: className }}
    />
  ))``

  return (
    <StyledTooltip
      title={tooltipText}
      arrow
      sx={{
        "& .MuiTooltip-tooltip": {
          borderRadius: "24px",
          padding: 2,
          display: "flex",
          width: "230px",
        },
      }}
    >
      <IconButton sx={{ padding: 0 }}>
        <Icons.Info />
      </IconButton>
    </StyledTooltip>
  )
}

export default InfoTooltip
