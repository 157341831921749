import React, { KeyboardEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import * as AccountAPI from "@app/api/account"
import { localizeYupSchema } from "@app/i18n"
import FormSubmitButton from "@app/uiKit/FormSubmitButton"
import { useAuth0 } from "@auth0/auth0-react"
import cn from "classnames"
import { Either, pipe } from "effect"
import { FormikProps, useFormik } from "formik"
import * as yup from "yup"

import { Icons } from "assets"

const connection = process.env.AUTH0_CONNECTION
const audience = process.env.AUTH0_AUDIENCE

type FormValues = {
  email: string
}

const initialValues: FormValues = {
  email: "",
}

const LoginScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const { loginWithRedirect } = useAuth0()
  const [emailServerError, setEmailServerError] = useState<string>("")

  const handleLogin = async ({ email }: FormValues): Promise<void> => {
    setEmailServerError("")
    const checkCreatedUser = await AccountAPI.checkEmailAvailability({ email })
    await pipe(
      checkCreatedUser,
      Either.match({
        onLeft: () => {
          const errorMessage = t("login.errors.authorization_error")
          setEmailServerError(errorMessage)
        },
        onRight: async ({ available }) => {
          if (!available) {
            const checkUserType = await AccountAPI.checkUserType({
              resource: email,
            })
            await pipe(
              checkUserType,
              Either.match({
                onLeft: () => {
                  const errorMessage = t("login.errors.authorization_error")
                  setEmailServerError(errorMessage)
                },
                onRight: async ({ type }) => {
                  if (type === "VIEWER") {
                    const ssoCheck = await AccountAPI.checkIsSSO({ email })
                    await pipe(
                      ssoCheck,
                      Either.match({
                        onLeft: () => null,
                        onRight: async ({ is_sso }) => {
                          await loginWithRedirect({
                            authorizationParams: {
                              scope: "openid profile email offline_access",
                              audience,
                              prompt: "login",
                              login_hint: email,
                              ...(is_sso && { connection }),
                            },
                          })
                        },
                      }),
                    )
                  } else {
                    const errorMessage = t("login.errors.user_permission_error")
                    setEmailServerError(errorMessage)
                  }
                },
              }),
            )
          } else {
            const errorMessage = t("login.errors.authorization_error")
            setEmailServerError(errorMessage)
          }
        },
      }),
    )
  }

  const validationSchema = localizeYupSchema<FormValues>(
    t,
    yup.object({
      email: yup.string().required().email(),
    }),
  )

  const formikProps = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleLogin,
    validateOnChange: false,
  })

  const hasServerError = Boolean(emailServerError)

  const containerClassName = cn(
    "sm:min-h-52 w-[75%] sm:w-[20%] sm:min-w-80 rounded-[32px] bg-zinc-800 p-6 sm:py-12 sm:px-16",
    {
      "h-[30%] max-h-60": hasServerError,
      "h-[28%] max-h-56 ": !hasServerError,
    },
  )

  return (
    <>
      <LoginScreenHeader />
      <div className="flex w-full h-full items-center justify-center">
        <div className={containerClassName}>
          <div className="text-xl sm:text-2xl font-medium pb-10">
            {t("login_screen.sign_in_eva_dashboard")}
          </div>
          <EmailInput {...formikProps} serverError={emailServerError} />
        </div>
      </div>
    </>
  )
}

const LoginScreenHeader = (): JSX.Element => {
  return (
    <div
      data-testid="login-logo-container"
      className="flex h-16 items-center justify-center pt-7 w-full"
    >
      <Icons.Logo />
    </div>
  )
}

type EmailInputProps = FormikProps<FormValues> & {
  serverError: string
}

const EmailInput = ({
  values,
  dirty,
  handleSubmit,
  handleChange,
  errors,
  isSubmitting,
  serverError,
}: EmailInputProps): JSX.Element => {
  const { t } = useTranslation()

  const emailIsNotEmpty = values.email

  const errorMessage = errors.email || serverError
  const hasError = Boolean(errorMessage)

  const emailIconClassName = emailIsNotEmpty
    ? hasError
      ? "stroke-red-500"
      : "stroke-emerald-100"
    : "stroke-stone-200"

  const isSubmitDisabled = !dirty || isSubmitting

  const handleLogin = (): void => {
    handleSubmit()
  }

  const inputClassName = cn(
    "block bg-zinc-800 border-none h-12 focus:outline-none sm:w-full ml-11 text-base custom-input",
    { "text-red-500": hasError },
  )

  const inputContainerClassName = cn(
    "relative w-full border-b border-solid border-x-0 border-t-0",
    { "border-red-500": hasError, "border-stone-500": !hasError },
  )

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      handleSubmit()
    }
  }

  return (
    <>
      <div className="pb-10">
        <div className="h-3 -mt-1">
          {emailIsNotEmpty && (
            <label htmlFor="emailAddress" className="text-xs block">
              {t("login_screen.email_address")}
            </label>
          )}
        </div>
        <div className={inputContainerClassName}>
          <div className="absolute mt-3 ml-3">
            <Icons.Email className={emailIconClassName} />
          </div>
          <input
            id="emailAddress"
            type="email"
            value={values.email}
            autoComplete="off"
            className={inputClassName}
            placeholder={t("login_screen.email_address")}
            onChange={handleChange("email")}
            onKeyDown={handleKeyDown}
          />
        </div>
        {hasError && (
          <div className={"min-h-[18px] text-left mt-2 text-red-500 text-xs"}>
            {errorMessage}
          </div>
        )}
      </div>
      <FormSubmitButton
        testID="login-next-button"
        onPress={handleLogin}
        isLoading={isSubmitting}
        isDisabled={isSubmitDisabled}
        text={t("login_screen.next")}
      />
    </>
  )
}

export default LoginScreen
