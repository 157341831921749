import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import * as ActiveUsersAPI from "@app/api/performanceAnalytics/getActiveUsers"
import * as ErrorHandler from "@app/errorHandler"
import { Either, pipe } from "effect"

import * as UIKit from "uiKit"

import { useDateFilterContext } from "../../DateFilterContext"

const ActiveUsers = (): JSX.Element => {
  const { t } = useTranslation()
  const [activeUsersPercent, setActiveUsersPercent] = useState<number>(0)
  const { getAPIDateFilter } = useDateFilterContext()

  useEffect(() => {
    const getActiveUsersPercentage = async (): Promise<void> => {
      const dateInterval = getAPIDateFilter()
      const result = await ActiveUsersAPI.getActiveUsers(dateInterval)
      pipe(
        result,
        Either.match({
          onLeft: error => {
            ErrorHandler.captureException(error)
          },
          onRight: response => {
            const integerResponse = Math.trunc(response.activePercentage)
            setActiveUsersPercent(integerResponse)
          },
        }),
      )
    }
    void getActiveUsersPercentage()
  }, [t, getAPIDateFilter])

  return (
    <div className="flex flex-col lg:w-full min-h-20 h-20 px-6 py-8 visualization justify-center">
      <div className="flex w-full justify-between pb-3 sm:pb-4">
        <span className="visualization-title">
          {t("performance_analytics.active_users")}
        </span>
        <UIKit.InfoTooltip
          text={
            <Trans i18nKey={"performance_analytics.active_users_tooltip"} />
          }
        />
      </div>
      <div className="flex w-full h-full justify-start items-end">
        <span className="text-4xl sm:text-5xl font-medium leading-0">
          {activeUsersPercent}%
        </span>
      </div>
    </div>
  )
}

export default ActiveUsers
