import * as NetworkError from "@app/api/networkError"
import { UserType } from "@app/model"
import { ParseError } from "@effect/schema/ParseResult"
import * as S from "@effect/schema/Schema"
import { Either, pipe } from "effect"

import { fetchWithoutAuth } from "../api"

export type CheckUserTypeError =
  | NetworkError.NetworkError
  | NetworkError.UnexpectedError
  | ParseError

export const checkUserType = async ({
  resource,
}: UserType.CheckUserTypeParams): Promise<UserType.Result> => {
  const queryParams: UserType.QueryParams = {
    resource,
  }
  const params = new URLSearchParams(queryParams).toString()
  const path = `sts/api/v2/dashboard/type/info?${params}`
  const response = await fetchWithoutAuth({
    method: "GET",
    path,
  })

  const result = (await response.json()) as unknown

  if (!response.ok) {
    if (NetworkError.isAPINetworkError(result)) {
      const error = NetworkError.toNetworkError(response.status, result)
      return Either.left(error)
    } else {
      const error = new NetworkError.UnexpectedError(`${result}`)
      return Either.left(error)
    }
  }

  return pipe(result, S.decodeUnknownEither(UserType.UserTypeSchema))
}
