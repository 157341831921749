import React from "react"
import { SupportUtils } from "@app/lib"

import { Icons } from "assets"

const Footer = (): JSX.Element => {
  return (
    <div className="flex justify-end h-16 mb-2 mr-2 z-[2] bottom-0 right-0 fixed">
      <button
        onClick={SupportUtils.handleSupportClick}
        type="button"
        className="border-none focus:outline-none cursor-pointer"
        style={{ backgroundColor: "transparent" }}
      >
        <Icons.Support />
      </button>
    </div>
  )
}

export default Footer
