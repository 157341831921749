import React from "react"
import { HashRouter, Navigate, Route, Routes } from "react-router-dom"
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"
import { CircularProgress } from "@mui/material"
import * as Sentry from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"

import { ActiveTabProvider } from "./ActiveTabContext"
import { DateFilterProvider } from "./DateFilterContext"
import Footer from "./Footer"
import Header from "./Header"
import LoginScreen from "./LoginScreen"
import { queryClient } from "./queryClient"
import { ScreenSizeProvider } from "./ScreenSizeContext"
import TabPanels from "./TabPanels"
import {
  UserProfileProvider,
  useUserProfileContext,
} from "./UserProfileContext"

const App = (): JSX.Element => {
  const domain = process.env.AUTH0_DOMAIN as string
  const clientId = process.env.AUTH0_CLIENT_ID as string
  const audience = process.env.AUTH0_AUDIENCE as string

  return (
    <Sentry.ErrorBoundary>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          scope: "openid profile email offline_access",
          audience: audience,
        }}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <QueryClientProvider client={queryClient}>
          <UserProfileProvider>
            <HashRouter>
              <AppView />
            </HashRouter>
          </UserProfileProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </Sentry.ErrorBoundary>
  )
}

export const Loading = (): JSX.Element => {
  return (
    <div className={"flex h-full items-center justify-center"}>
      <CircularProgress />
    </div>
  )
}

const AppView = (): JSX.Element => {
  const { isAuthenticated, isLoading } = useAuth0()
  const { userReady } = useUserProfileContext()

  if (isLoading) {
    return <Loading />
  }

  return (
    <Routes>
      <Route index element={<Navigate to="/dashboard" replace />} />
      <Route path="login" element={<NonAuthenticatedApp />} />
      <Route
        path="dashboard"
        element={
          isAuthenticated ? (
            userReady ? (
              <AuthenticatedApp />
            ) : (
              <Loading />
            )
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="*" element={<NonAuthenticatedApp />} />
    </Routes>
  )
}

const NonAuthenticatedApp = (): JSX.Element => {
  return (
    <div className="flex flex-col h-full relative">
      <LoginScreen />
      <Footer />
    </div>
  )
}

const AuthenticatedApp = (): JSX.Element => {
  return (
    <ScreenSizeProvider>
      <ActiveTabProvider>
        <DateFilterProvider>
          <AuthenticatedAppView />
        </DateFilterProvider>
      </ActiveTabProvider>
    </ScreenSizeProvider>
  )
}

const AuthenticatedAppView = (): JSX.Element => {
  return (
    <div className="flex flex-col h-full relative">
      <Header />
      <TabPanels />
      <Footer />
    </div>
  )
}

export default App
