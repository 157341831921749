import React from "react"
import { useTranslation } from "react-i18next"

const TotalSetCheckIns = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className="flex h-[80%] min-h-72 p-6 visualization">
      <span className="visualization-title">
        {t("performance_analytics.total_set_checkins")}
      </span>
    </div>
  )
}

export default TotalSetCheckIns
