import { CurrentUser } from "@app/model"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery, UseQueryResult } from "@tanstack/react-query"

import { buildAuthHeader, fetchWithAuth } from "../api"
import { QueryKey } from "../queryKey"

import * as APICurrentUser from "./apiCurrentUser"

export type Key = string

export const getCurrentUser = async (
  token: string,
): Promise<CurrentUser.CurrentUser> => {
  const response = await fetchWithAuth({
    method: "GET",
    path: `sts/api/v2/user/me`,
    authHeader: buildAuthHeader(token),
  })

  if (!response.ok) {
    throw new Error()
  }

  const result = (await response.json()) as APICurrentUser.APICurrentUser

  return APICurrentUser.APICurrentUserToModel(result)
}

export const useGetCurrentUserQuery = (): UseQueryResult<
  CurrentUser.CurrentUser,
  Error
> => {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery<CurrentUser.CurrentUser, Error>({
    queryKey: QueryKey.currentUser,
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      return getCurrentUser(token)
    },
  })
}
