import { useTranslation } from "react-i18next"
import { Icons } from "@app/assets"

const BarChartLegend = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-row mr-6 items-center">
      <Icons.Circle className="fill-cyan-600" />
      <span className="ml-2">{t("actionable_data.selected")}</span>
      <div className="flex ml-7 items-center">
        <Icons.Circle className="fill-cyan-200" />
        <span className="ml-2">{t("actionable_data.previous")}</span>
      </div>
    </div>
  )
}

export default BarChartLegend
