import React, { createContext, ReactNode, useState } from "react"
import { APIDateFilters, DateFilterTab } from "@app/model/dateFilterTab"

interface DateFilterContextType {
  dateFilter: DateFilterTab
  updateDateFilter: (tab: DateFilterTab) => void
  getAPIDateFilter: () => string
}

export const DateFilterContext = createContext<DateFilterContextType>({
  dateFilter: DateFilterTab.Last30Days,
  updateDateFilter: () => {},
  getAPIDateFilter: () => "",
})

type DateFilterProviderProps = {
  children: ReactNode
}

export const DateFilterProvider = ({
  children,
}: DateFilterProviderProps): JSX.Element => {
  const [dateFilter, setDateFilter] = useState<DateFilterTab>(
    DateFilterTab.Last30Days,
  )

  const updateDateFilter = (filter: DateFilterTab): void => {
    setDateFilter(filter)
  }

  const getAPIDateFilter = (): string => {
    return APIDateFilters[dateFilter]
  }

  return (
    <DateFilterContext.Provider
      value={{ dateFilter, updateDateFilter, getAPIDateFilter }}
    >
      {children}
    </DateFilterContext.Provider>
  )
}

export const useDateFilterContext = (): DateFilterContextType => {
  const context = React.useContext(DateFilterContext)
  if (context === undefined) {
    throw new Error(
      "useDateFilterContext must be used within a DateFilterProvider",
    )
  }
  return context
}
