import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import * as RecentReportAPI from "@app/api/actionableData/getRecentReports"
import { useDateFilterContext } from "@app/App/DateFilterContext"
import { Icons } from "@app/assets"
import * as ErrorHandler from "@app/errorHandler"
import { DateUtils } from "@app/lib"
import { RecentReport } from "@app/model"
import cn from "classnames"
import { Either, pipe } from "effect"

import ErrorMessage from "./ErrorMessage"
import NoDataAvailable from "./NoDataAvailable"

const RecentReports = (): JSX.Element => {
  const { t } = useTranslation()
  const [page, setPage] = React.useState<number>(1)
  const [recentReports, setRecentReports] = React.useState<
    RecentReport.RecentReport[]
  >([])
  const [hasError, setHasError] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const { getAPIDateFilter } = useDateFilterContext()
  const dateInterval = getAPIDateFilter()

  const {
    data,
    error: apiError,
    isLoading,
  } = RecentReportAPI.useGetRecentReportsQuery(dateInterval, page)

  useEffect(() => {
    if (data) {
      pipe(
        data,
        Either.match({
          onLeft: error => {
            setHasError(true)
            ErrorHandler.captureException(error)
          },
          onRight: response => {
            if (page === 1) {
              setRecentReports(response)
            } else {
              setRecentReports(prevReports => [...prevReports, ...response])
            }
          },
        }),
      )
    }
  }, [data, page, isLoading])

  useEffect(() => {
    if (apiError) {
      setHasError(true)
      ErrorHandler.captureException(apiError)
    }
  }, [apiError])

  useEffect(() => {
    setPage(1)
  }, [dateInterval])

  const handleScroll = useCallback((): void => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current
      if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
        setPage(prevPage => prevPage + 1)
      }
    }
  }, [isLoading])

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll)
      }
    }
  }, [isLoading, handleScroll])

  return (
    <div className="flex flex-col lg:w-full lg:h-[78.25vh] lg:min-h-[40rem] min-h-72 p-6 visualization">
      <span className="visualization-title pb-7">
        {t("actionable_data.recent_reports")}
      </span>
      {recentReports.length > 0 ? (
        <div
          className="flex-1 overflow-y-auto overflow-x-hidden scrollbar"
          ref={containerRef}
        >
          <ReportList reports={recentReports} />
        </div>
      ) : (
        !isLoading && (hasError ? <ErrorMessage /> : <NoDataAvailable />)
      )}
    </div>
  )
}

type ReportListProps = {
  reports: RecentReport.RecentReport[]
}

const ReportList = ({ reports }: ReportListProps): JSX.Element => {
  const [dropdownStates, setDropdownStates] = useState<boolean[]>([])

  useEffect(() => {
    if (reports.length > 0) {
      setDropdownStates(reports.map(() => false))
    }
  }, [reports])

  const handleSetDropdownState = (index: number): void => {
    setDropdownStates(prevStates =>
      prevStates.map((isOpen, i) => (i === index ? !isOpen : isOpen)),
    )
  }

  return (
    <>
      {reports.map((report, index) => (
        <Report
          key={report.reportId}
          report={report}
          isLast={index === reports.length - 1}
          dropdownState={dropdownStates[index]}
          toggleDropdown={() => handleSetDropdownState(index)}
        />
      ))}
    </>
  )
}

type ReportProps = {
  report: RecentReport.RecentReport
  isLast: boolean
  dropdownState: boolean | undefined
  toggleDropdown: () => void
}

const Report = ({
  report,
  isLast,
  dropdownState,
  toggleDropdown,
}: ReportProps): JSX.Element => {
  const { t } = useTranslation()
  const { threats, additionalDetails, mediaUrl, location, reportedAt } = report

  const showAdditionalInformation = (details?: string): boolean => {
    return (Boolean(details?.trim()) && details !== "No") || Boolean(mediaUrl)
  }

  const renderMedia = (): ReactNode =>
    dropdownState &&
    mediaUrl && <img src={mediaUrl} className="rounded-2xl max-h-52" />

  const renderAdditionalInformation = (): ReactNode =>
    dropdownState &&
    showAdditionalInformation(additionalDetails) && (
      <div className="text-sm pb-1">
        <span className="font-medium">{additionalDetails}</span>
      </div>
    )

  const additionalInformationClassName = cn(
    "flex flex-row justify-between items-center",
    { "pb-1.5 sm:pb-1": dropdownState },
  )
  return (
    <>
      <div className="py-6 mx-3">
        <div className="flex flex-row items-center pb-3 sm:pb-2.5">
          <div className="flex items-center text-lg sm:text-xl font-medium tracking-tighter pr-5 sm:pr-0">
            {DateUtils.formatTimestampToDate(reportedAt)}
          </div>
        </div>

        <div className="flex flex-row flex-wrap pb-1 sm:pb-0.5">
          <div className="px-3 py-1 rounded-3xl bg-emerald-100 mr-2 mb-2">
            <div className="text-slate-900 text-xs sm:text-sm sm:leading-5">
              {location}
            </div>
          </div>
          <IncidentTypes threats={threats} />
        </div>

        {showAdditionalInformation(additionalDetails) && (
          <div className={additionalInformationClassName}>
            <span className="font-bold text-base sm:text-lg">
              {t("actionable_data.additional_information")}
            </span>
            <DropdownToggleButton
              dropdownState={dropdownState}
              toggleDropdown={toggleDropdown}
            />
          </div>
        )}

        {renderAdditionalInformation()}

        {renderMedia()}
      </div>
      {!isLast && <hr color="#1B1F2A" />}
    </>
  )
}

type DropdownToggleButtonProps = {
  dropdownState: boolean | undefined
  toggleDropdown: () => void
}

const DropdownToggleButton = ({
  dropdownState,
  toggleDropdown,
}: DropdownToggleButtonProps): JSX.Element => {
  const arrowClassName = "h-9 w-9 stroke-emerald-300"
  return (
    <button
      type="button"
      className="bg-zinc-800 border-none cursor-pointer p-0 flex items-center"
      onClick={toggleDropdown}
    >
      {dropdownState ? (
        <Icons.UpArrow className={arrowClassName} />
      ) : (
        <Icons.DownArrow className={arrowClassName} />
      )}
    </button>
  )
}

type IncidentTypesProps = {
  threats: string[]
}

const IncidentTypes = ({ threats }: IncidentTypesProps): JSX.Element[] => {
  return threats.map(incidentType => (
    <div
      key={incidentType}
      className="px-3 py-1 rounded-3xl bg-gray-600 mr-2 mb-2"
    >
      <div className="text-emerald-100 text-xs sm:text-sm sm:leading-5">
        {incidentType}
      </div>
    </div>
  ))
}

export default RecentReports
