export type RequestMethod = "GET" | "POST" | "PATCH" | "PUT" | "DELETE"
export const REST_URL = process.env.PUBLIC_REST_URL

export type AuthHeader = {
  Authorization: string
}

export const buildAuthHeader = (token: string): AuthHeader => {
  return {
    Authorization: `Bearer ${token}`,
  }
}

type RequestBodyData =
  | Record<string, unknown>
  | Record<string, unknown>[]
  | string
  | string[]

type FetchWithAuthParams = {
  path: string
  method: RequestMethod
  body?: RequestBodyData | FormData
  authHeader: AuthHeader
}

export const fetchWithAuth = async (
  params: FetchWithAuthParams,
): Promise<Response> => {
  const { path, method, body, authHeader } = params

  const endpoint = `${REST_URL}/${path}`
  const headers = {
    ...authHeader,
    "Content-Type": "application/json",
  }
  return fetch(endpoint, {
    headers,
    method,
    body: JSON.stringify(body),
  })
}

type FetchWithoutAuthParams = {
  path: string
  method: RequestMethod
  body?: RequestBodyData | FormData
  extraHeaders?: Record<string, unknown>
}
export const fetchWithoutAuth = async (
  params: FetchWithoutAuthParams,
): Promise<Response> => {
  const { path, method, body, extraHeaders } = params

  const endpoint = `${REST_URL}/${path}`
  const headers = {
    "Content-Type": "application/json",
    ...extraHeaders,
  }
  return fetch(endpoint, {
    headers,
    method,
    body: JSON.stringify(body),
  })
}
