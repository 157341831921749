import React, { ReactNode } from "react"
import { useScreenSizeContext } from "@app/App/ScreenSizeContext"
import { IncidentType, Location } from "@app/model"
import cn from "classnames"

import { Icons } from "assets"

type PercentChangeProps = {
  items: Location.Location[] | IncidentType.IncidentType[]
}

const PercentChange = ({ items }: PercentChangeProps): JSX.Element => {
  const { width } = useScreenSizeContext()

  const getChangeIcon = (
    item: Location.Location | IncidentType.IncidentType,
  ): ReactNode => {
    const sizeClassName = "w-3 h-3 sm:w-6 sm:h-6"
    if (item.change > 0)
      return <Icons.IncreaseArrow className={sizeClassName} />
    if (item.change < 0)
      return <Icons.DecreaseArrow className={sizeClassName} />

    const iconClassName =
      item.change === 0 && (item.selected !== 0 || item.previous !== 0)
        ? "stroke-yellow-400"
        : "stroke-zinc-500"

    return <Icons.HorizontalLine className={cn(iconClassName, sizeClassName)} />
  }

  const iconClassName = "flex ml-2 items-center"

  return (
    <div className="flex h-full items-center">
      <div className="flex flex-col h-[85%] justify-around items-end">
        {items.map((item, index) => (
          <div className="flex flex-row" key={index}>
            <div className="flex items-center">
              {width > 1200 && (
                <span className="text-xl">{Math.abs(item.change)}%</span>
              )}
              <div className={iconClassName}>{getChangeIcon(item)}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PercentChange
