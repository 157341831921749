import { useTranslation } from "react-i18next"

const NoDataAvailable = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col h-full items-center justify-center px-12 sm:px-16 text-center">
      <div className="text-3xl sm:text-5xl text-red-400 pb-6 sm:pb-10 font-medium tracking-tight">
        {t("actionable_data.no_available_data")}
      </div>
      <div className="text-lg leading-8 sm:text-2xl sm:leading-9 font-medium tracking-tighter">
        {t("actionable_data.no_data_description")}
      </div>
    </div>
  )
}

export default NoDataAvailable
