import React, { createContext, ReactNode, useEffect, useState } from "react"

interface ScreenSizeContextType {
  width: number
  isSmallScreenSize: boolean
  isMediumScreenSize: boolean
}

export const ScreenSizeContext = createContext<ScreenSizeContextType>({
  width: window.innerWidth,
  isSmallScreenSize: window.innerWidth < 640,
  isMediumScreenSize: window.innerWidth < 1400,
})

type ScreenSizeProviderProps = {
  children: ReactNode
}

export const ScreenSizeProvider = ({
  children,
}: ScreenSizeProviderProps): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth)
  const isSmallScreenSize = width < 640
  const isMediumScreenSize = width < 1400

  useEffect(() => {
    const handleResize = (): void => {
      setWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <ScreenSizeContext.Provider
      value={{ width, isSmallScreenSize, isMediumScreenSize }}
    >
      {children}
    </ScreenSizeContext.Provider>
  )
}

export const useScreenSizeContext = (): ScreenSizeContextType => {
  const context = React.useContext(ScreenSizeContext)
  if (context === undefined) {
    throw new Error(
      "useScreenSizeContext must be used within a ScreenSizeProvider",
    )
  }
  return context
}
