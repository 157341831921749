import { ActiveUsers } from "@app/model"

export type APIActiveUsers = {
  interval: string
  totalUsers: number
  activeUsers: number
  activePercentage: number
}

export const APIActiveUsersToModel = ({
  interval,
  totalUsers,
  activeUsers,
  activePercentage,
}: APIActiveUsers): ActiveUsers.ActiveUsers => {
  return {
    interval,
    totalUsers,
    activeUsers,
    activePercentage,
  }
}
