import React from "react"
import { useTranslation } from "react-i18next"

const TotalReports = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className="flex min-h-72 lg:w-1/2 p-6 visualization">
      <span className="visualization-title">
        {t("performance_analytics.total_reports")}
      </span>
    </div>
  )
}

export default TotalReports
