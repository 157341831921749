import React from "react"
import { DashboardTab } from "@app/model/dashboardTab"

import { useActiveTabContext } from "../ActiveTabContext"

import ActionableData from "./ActionableData"
import PerformanceAnalytics from "./PerformanceAnalytics"

const TabPanels = (): JSX.Element => {
  const { activeTab } = useActiveTabContext()
  return (
    <div className="flex mb-5 lg:mb-10 px-5 lg:px-10 h-full w-full box-border">
      {activeTab === DashboardTab.ActionableData ? (
        <ActionableData />
      ) : (
        <PerformanceAnalytics />
      )}
    </div>
  )
}

export default TabPanels
