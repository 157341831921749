import { TFunction } from "i18next"
import { Schema, setLocale } from "yup"

const setYupLocale = (t: TFunction): void => {
  setLocale({
    mixed: {
      default: t("form_validation.field_invalid"),
      required: t("form_validation.field_required"),
      notType: ({ type }) => {
        switch (type) {
          case "number":
            return t("form_validation.number_invalid")
          default:
            return t("form_validation.field_invalid")
        }
      },
    },
    string: {
      email: t("form_validation.email_invalid"),
    },
  })
}

export const localizeYupSchema = <T>(
  t: TFunction,
  schema: Schema<T>,
): Schema<T> => {
  setYupLocale(t)
  return schema
}
