import * as NetworkError from "@app/api/networkError"
import { RecentReport } from "@app/model"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { Either } from "effect"

import { buildAuthHeader, fetchWithAuth } from "../api"
import { QueryKey } from "../queryKey"

import * as APIRecentReports from "./apiRecentReports"

export type GetRecentReportsError =
  | NetworkError.NetworkError
  | NetworkError.UnexpectedError

type Result = Either.Either<RecentReport.RecentReport[], GetRecentReportsError>

type GetRecentReportsParams = {
  period?: string
  page?: string
  limit?: string
}

export const getRecentReports = async (
  period: string = "30d",
  page: number = 1,
  limit: number = 10,
  token: string,
): Promise<Result> => {
  const queryParams: GetRecentReportsParams = {
    period,
    page: page.toString(),
    limit: limit.toString(),
  }

  const params = new URLSearchParams(queryParams).toString()
  const path = `report/api/v2/stats/reports?${params}`

  const response = await fetchWithAuth({
    method: "GET",
    path,
    authHeader: buildAuthHeader(token),
  })

  if (!response.ok) {
    const result = (await response.json()) as unknown

    if (NetworkError.isAPINetworkError(result)) {
      const error = NetworkError.toNetworkError(response.status, result)
      return Either.left(error)
    } else {
      const error = new NetworkError.UnexpectedError(`${result}`)
      return Either.left(error)
    }
  }

  const result = (await response.json()) as {
    reports: APIRecentReports.APIRecentReport[]
  }

  return Either.right(APIRecentReports.APIRecentReportsToModel(result.reports))
}

export const useGetRecentReportsQuery = (
  period: string = "30d",
  page: number = 1,
  limit: number = 10,
): UseQueryResult<Result, Error> => {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery<Result, Error>({
    queryKey: QueryKey.recentReports(period, page),
    queryFn: async () => {
      const token = await getAccessTokenSilently()
      return getRecentReports(period, page, limit, token)
    },
  })
}
