import React from "react"
import { useScreenSizeContext } from "@app/App/ScreenSizeContext"
import { backgroundData } from "@app/model/barChartBackgroundData"
import { Box } from "@mui/material"
import { AxisConfig, ChartsYAxisProps } from "@mui/x-charts"
import { BarChart } from "@mui/x-charts/BarChart"

type HorizontalBarChartProps = {
  yAxisData: string[]
  selectedData: number[]
  previousData: number[]
  fullSizeBars?: boolean
}

const HorizontalBarChart = ({
  yAxisData,
  selectedData,
  previousData,
  fullSizeBars,
}: HorizontalBarChartProps): JSX.Element => {
  const { isSmallScreenSize, isMediumScreenSize } = useScreenSizeContext()

  const chartMargin = {
    top: 20,
    bottom: 20,
    left: isSmallScreenSize ? 120 : 200,
    ...(fullSizeBars && { right: 0 }),
    ...(isSmallScreenSize && !fullSizeBars && { right: 10 }),
    ...(isMediumScreenSize && !fullSizeBars && { right: 30 }),
  }

  const chartStyle = {
    width: "100%",
    height: "100%",
  }

  const dataSeries = [
    {
      data: selectedData,
      color: "#0891B2",
      label: "Selected",
    },
    ...(isSmallScreenSize
      ? []
      : [
          {
            data: previousData,
            color: "#D3F6FC",
            label: "Previous",
          },
        ]),
  ]

  const backgroundSeries = [
    {
      data: backgroundData.map(item => item.selected),
      color: "#1B1F2A",
    },
    ...(isSmallScreenSize
      ? []
      : [
          {
            data: backgroundData.map(item => item.previous),
            color: "#1B1F2A",
          },
        ]),
  ]
  return (
    <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
        }}
      >
        <BarChart
          yAxis={[
            {
              scaleType: "band",
              data: yAxisData,
              disableLine: true,
              disableTicks: true,
              tickLabelStyle: {
                fontSize: isSmallScreenSize ? 12 : 20,
                fill: "#EAFFFE",
                transform: isSmallScreenSize
                  ? "translateX(-110px)"
                  : "translateX(-175px)",
                textAnchor: "start",
              },
              ...(isSmallScreenSize && { categoryGapRatio: 0.5 }),
            } as AxisConfig<"band", unknown, ChartsYAxisProps>,
          ]}
          series={dataSeries}
          layout="horizontal"
          bottomAxis={null}
          slotProps={{
            noDataOverlay: { message: "" },
            bar: { rx: 15, ry: 15 },
            legend: { hidden: true },
            popper: {
              sx: {
                ["& .MuiTypography-root"]: {
                  color: "#1B1F2A",
                  fontFamily: "DM Sans",
                },
              },
            },
          }}
          margin={chartMargin}
          sx={chartStyle}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 0,
        }}
      >
        <BarChart
          yAxis={[
            {
              scaleType: "band",
              data: backgroundData.map(item => item.label),
              disableLine: true,
              disableTicks: true,
              tickLabelStyle: {
                display: "none",
              },
              ...(isSmallScreenSize && { categoryGapRatio: 0.5 }),
            } as AxisConfig<"band", unknown, ChartsYAxisProps>,
          ]}
          series={backgroundSeries}
          layout="horizontal"
          bottomAxis={null}
          slotProps={{
            bar: { rx: 15, ry: 15 },
            legend: { hidden: true },
            popper: { hidden: true },
          }}
          margin={chartMargin}
          sx={chartStyle}
        />
      </Box>
    </Box>
  )
}

export default HorizontalBarChart
