{
  "actionable_data": {
    "additional_information": "Additional Information",
    "error_description": "The server might be down. Please refresh the page or contact support if the issue persists.",
    "no_available_data": "No available data",
    "no_data_description": "Please refresh the page or try adjusting your filters to view the data.",
    "previous": "Previous",
    "recent_reports": "Recent Reports",
    "selected": "Selected",
    "top_incident_types": "Top Incident Types",
    "top_locations": "Top Locations",
    "unable_to_fetch": "Unable to fetch data"
  },
  "form_validation": {
    "email_invalid": "Enter a valid email",
    "field_invalid": "Field invalid",
    "field_required": "Field required",
    "number_invalid": "Must be a number"
  },
  "header": {
    "profile": {
      "dropdown": {
        "log_out": "Log Out"
      },
      "hi": "Hi"
    },
    "tabs": {
      "actionable_data": "Actionable Data",
      "date": {
        "last_24_hours": "Last 24 hours",
        "last_30_days": "Last 30 days",
        "last_7_days": "Last 7 days",
        "this_year": "This Year"
      },
      "filter_search": "Filter Search",
      "performance_analytics": "Performance Analytics"
    }
  },
  "login": {
    "errors": {
      "authorization_error": "Email does not exist. Please try again.",
      "user_permission_error": "This email doesn't have permissions to access Admin Dashboard. Please, contact your admin."
    }
  },
  "login_screen": {
    "email_address": "Email Address",
    "next": "Next",
    "sign_in_eva_dashboard": "Sign in to Eva Dashboard"
  },
  "performance_analytics": {
    "active_users": "Active Users",
    "active_users_tooltip": "A user that opens their eva app once a day would be considered an <strong>active user</strong> for that respective day",
    "all_time": "All Time",
    "live_agent_activities": "Live Agent Activities",
    "most_used_features": "Most Used Features",
    "total_reports": "Total Reports",
    "total_set_checkins": "Total Set Check-Ins",
    "total_users": "Total Users"
  }
}
