import { DateUtils } from "@app/lib"
import { CurrentUser } from "@app/model"

export type APICurrentUser = {
  id: string
  email: string
  loginCount: number
  firstName: string
  lastName: string
  zipCode: string
  lastLoginAt: DateUtils.ISOString
  createdAt: DateUtils.ISOString
  updatedAt: DateUtils.ISOString
  phoneNumber: string
  emergencyContact: {
    phoneNumber: string
    firstName: string
    lastName: string
    relationship: string
  }
}

export const APICurrentUserToModel = ({
  id,
  email,
  loginCount,
  firstName,
  lastName,
  zipCode,
  lastLoginAt,
  createdAt,
  updatedAt,
  phoneNumber,
  emergencyContact,
}: APICurrentUser): CurrentUser.CurrentUser => {
  return {
    id,
    email,
    loginCount,
    firstName,
    lastName,
    zipCode,
    lastLoginAt,
    createdAt,
    updatedAt,
    phoneNumber,
    emergencyContact: {
      firstName: emergencyContact.firstName,
      lastName: emergencyContact.lastName,
      relationship: emergencyContact.relationship,
      phoneNumber: emergencyContact.phoneNumber,
    },
  }
}
