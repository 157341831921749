export enum DateFilterTab {
  ThisYear = 0,
  Last30Days = 1,
  Last7Days = 2,
  Last24Hours = 3,
}

export const APIDateFilters = {
  [DateFilterTab.ThisYear]: "1y",
  [DateFilterTab.Last30Days]: "30d",
  [DateFilterTab.Last7Days]: "7d",
  [DateFilterTab.Last24Hours]: "24h",
}

export const allTabs: DateFilterTab[] = [
  DateFilterTab.ThisYear,
  DateFilterTab.Last30Days,
  DateFilterTab.Last7Days,
  DateFilterTab.Last24Hours,
]
