import React from "react"

import RecentReports from "./RecentReports"
import TopIncidentTypes from "./TopIncidentTypes"
import TopLocations from "./TopLocations"

const ActionableData = (): JSX.Element => {
  return (
    <div data-testid="actionable-data-page" className="page">
      <div className="left-column lg:max-w-[512px] order-2 lg:order-1 pt-4 lg:pt-0">
        <RecentReports />
      </div>
      <div className="right-column order-1 lg:order-2">
        <TopLocations />
        <TopIncidentTypes />
      </div>
      <div className="pb-5 lg:pb-0 order-3" />
    </div>
  )
}

export default ActionableData
