import React from "react"

import ActiveUsers from "./ActiveUsers"
import LiveAgentActivities from "./LiveAgentActivities"
import MostUsedFeatures from "./MostUsedFeatures"
import TotalReports from "./TotalReports"
import TotalSetCheckIns from "./TotalSetCheckIns"
import TotalUsers from "./TotalUsers"

const PerformanceAnalytics = (): JSX.Element => {
  return (
    <div data-testid="performance-analytics-page" className="page">
      <div className="left-column lg:max-w-96">
        <TotalUsers />
        <ActiveUsers />
        <LiveAgentActivities />
      </div>
      <div className="right-column">
        <TotalSetCheckIns />
        <div className="flex flex-col lg:flex-row h-full lg:space-x-4 space-y-4 lg:space-y-0">
          <TotalReports />
          <MostUsedFeatures />
        </div>
      </div>
      <div className="pb-5 lg:pb-0" />
    </div>
  )
}

export default PerformanceAnalytics
