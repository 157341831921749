import * as S from "@effect/schema/Schema"
import { Either } from "effect"

import { CheckUserTypeError } from "api/account"

export type UserType = { type: "PROFILE" | "VIEWER" }

export type QueryParams = {
  resource: string
}

export const UserTypeSchema = S.struct({
  type: S.literal("PROFILE", "VIEWER"),
})

export type Result = Either.Either<UserType, CheckUserTypeError>

export type CheckUserTypeParams = {
  resource: string
}
