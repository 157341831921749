import * as S from "@effect/schema/Schema"
import { Option, pipe, ReadonlyArray } from "effect"

// ---- API Model

type APIValidationError = unknown
type APIRegularError = string

export interface APINetworkError {
  readonly errors:
    | readonly APIRegularError[]
    | readonly (readonly APIValidationError[])[]
}

const APIRegularErrorsSchema: S.Schema<readonly APIRegularError[]> = S.array(
  S.string,
)

export const APINetworkError: S.Schema<APINetworkError> = S.struct({
  errors: S.union(APIRegularErrorsSchema, S.array(S.array(S.any))),
})

export const isAPINetworkError = (
  parsed: unknown,
): parsed is APINetworkError => {
  return (parsed as APINetworkError).errors !== undefined
}

export const toRegularAPIErrorMessage = (
  error: APINetworkError,
): Option.Option<APIRegularError> => {
  return pipe(
    error.errors,
    S.decodeUnknownOption(APIRegularErrorsSchema),
    Option.match({
      onSome: ReadonlyArray.head,
      onNone: Option.none,
    }),
  )
}

export const toNetworkError = (
  status: number,
  error: APINetworkError,
): NetworkError => {
  switch (status) {
    case 400:
      return new BadRequestError(error)
    case 401:
      return new AuthorizationError(error)
    case 403:
      return new ForbiddenError(error)
    case 404:
      return new NotFoundError(error)
    case 406:
      return new NotAcceptableError(error)
    case 409:
      return new ResourceConflictError(error)
    case 410:
      return new VerificationError(error)
    case 422:
      return new UnprocessableContentError(error)
    default:
      return new UnhandledNetworkError(status, error)
  }
}

// ---- Model

class BaseNetworkError {
  constructor(readonly error: APINetworkError) {}
}

export class BadRequestError extends BaseNetworkError {
  readonly _tag = "BadRequestError"
  readonly status = 400
}

export class AuthorizationError extends BaseNetworkError {
  readonly _tag = "AuthorizationError"
  readonly status = 401
}

export class ForbiddenError extends BaseNetworkError {
  readonly _tag = "ForbiddenError"
  readonly status = 403
}

export class NotFoundError extends BaseNetworkError {
  readonly _tag = "NotFoundError"
  readonly status = 404
}

export class NotAcceptableError extends BaseNetworkError {
  readonly _tag = "NotAcceptableError"
  readonly status = 406
}

export class MethodNotAllowedError extends BaseNetworkError {
  readonly _tag = "MethodNotAllowedError"
  readonly status = 405
}

export class ResourceConflictError extends BaseNetworkError {
  readonly _tag = "ResourceConflictError"
  readonly status = 409
}

export class UnprocessableContentError extends BaseNetworkError {
  readonly _tag = "UnprocessableContentError"
  readonly status = 422
}

export class VerificationError extends BaseNetworkError {
  readonly _tag = "VerificationError"
  readonly status = 410
}

export class UnhandledNetworkError extends BaseNetworkError {
  readonly _tag = "UnhandledNetworkError"
  constructor(
    readonly status: number,
    readonly error: APINetworkError,
  ) {
    super(error)
  }
}

export type NetworkError =
  | ForbiddenError
  | NotFoundError
  | NotAcceptableError
  | AuthorizationError
  | ResourceConflictError
  | UnprocessableContentError
  | VerificationError
  | UnhandledNetworkError
  | BadRequestError

export class UnexpectedError {
  readonly _tag = "UnexpectedError"
  constructor(readonly message: string) {}
}
