import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Drawer } from "@mui/material"
import cn from "classnames"

import { Icons } from "assets"

import DashboardTabs from "./DashboardTabs"
import DateFilterTabs from "./DateFilterTabs"

const HamburgerDrawer = (): JSX.Element => {
  const [isDrawerOpen, setDrawerState] = useState(false)

  const handleSetDrawerState = (): void => {
    setDrawerState(!isDrawerOpen)
  }

  const closeDrawer = (): void => {
    setDrawerState(false)
  }
  return (
    <>
      <button
        className="flex mr-4 bg-slate-900 cursor-pointer border-none"
        type="button"
        data-testid="hamburger-button"
        onClick={handleSetDrawerState}
      >
        <Icons.Hamburger />
      </button>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: "#24272F",
            padding: "30px",
            width: "280px",
          },
        }}
        open={isDrawerOpen}
        onClose={closeDrawer}
        data-testid="drawer-menu"
        sx={{
          "& .MuiModal-backdrop": {
            backdropFilter: "blur(2px)",
            backgroundColor: "#0000004d",
          },
        }}
      >
        <DrawerHeader closeDrawer={closeDrawer} />
        <DashboardTabs
          tabClassName="px-4 bg-zinc-800"
          tabListClassName="flex-col space-y-12 items-start"
          selectedTabChildClassName="text-emerald-500 stroke-emerald-500"
        />
        <CollapsibleFilter />
      </Drawer>
    </>
  )
}

type DrawerHeaderProps = {
  closeDrawer: () => void
}

const DrawerHeader = ({ closeDrawer }: DrawerHeaderProps): JSX.Element => {
  return (
    <div className="flex flex-row items-center pb-12">
      <div className="h-6">
        <Icons.Logo />
      </div>

      <button
        type="button"
        className="flex bg-zinc-800 cursor-pointer border-none ml-auto p-0"
        onClick={closeDrawer}
      >
        <Icons.Close />
      </button>
    </div>
  )
}

const CollapsibleFilter = (): JSX.Element => {
  const [isCollapsibleOpen, setCollapsibleState] = useState(false)
  const { t } = useTranslation()

  const handleSetCollapsibleState = (): void => {
    setCollapsibleState(!isCollapsibleOpen)
  }

  const arrowClassName = "h-8 w-8"
  const containerClassName = cn("flex flex-col mt-9 ", {
    "rounded-2xl thin-bdr-emerald py-3.75 px-2.75": isCollapsibleOpen,
    "py-4 px-3": !isCollapsibleOpen,
  })

  return (
    <div className={containerClassName}>
      <button
        type="button"
        className="row-button bg-zinc-800 p-0 active:bg-zinc-800"
        onClick={handleSetCollapsibleState}
      >
        <div className="flex items-center pl-1 space-x-4">
          <Icons.Filter className="h-7 w-7" />
          <span className="text-lg">{t("header.tabs.filter_search")}</span>
        </div>
        <div className="ml-auto">
          {isCollapsibleOpen ? (
            <Icons.UpArrow className={arrowClassName} />
          ) : (
            <Icons.DownArrow className={arrowClassName} />
          )}
        </div>
      </button>
      {isCollapsibleOpen && (
        <DateFilterTabs
          tabListClassName="flex-col pl-3 pt-6"
          selectedTabClassName="bg-zinc-800"
          selectedTabChildClassName="text-emerald-500"
        />
      )}
    </div>
  )
}

export default HamburgerDrawer
