import { IncidentType } from "@app/model"

export type APITopIncidentType = {
  threat: string
  selected: number
  previous: number
  change: number
}

export const APITopIncidentTypesToModel = (
  topIncidentTypes: APITopIncidentType[],
): IncidentType.IncidentType[] => {
  return topIncidentTypes.map(({ threat, selected, previous, change }) => {
    return {
      threat,
      selected,
      previous,
      change,
    }
  })
}
