import React, { RefObject, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuth0 } from "@auth0/auth0-react"
import { Backdrop } from "@mui/material"
import cn from "classnames"
import { Option, pipe, String } from "effect"

import { Icons } from "assets"
import * as UIKit from "uiKit"

import { useScreenSizeContext } from "../ScreenSizeContext"
import { useUserProfileContext } from "../UserProfileContext"

const ProfileHeader = (): JSX.Element => {
  const [isDropdownOpen, setDropdownState] = useState(false)
  const { isSmallScreenSize } = useScreenSizeContext()
  const { t } = useTranslation()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const {
    userProfile: { firstName, lastName },
  } = useUserProfileContext()

  const firstInitial = pipe(
    firstName,
    String.at(0),
    Option.map(String.toUpperCase),
    Option.getOrElse(() => "?"),
  )
  const lastInitial = pipe(
    lastName,
    String.at(0),
    Option.map(String.toUpperCase),
    Option.getOrElse(() => "?"),
  )
  const displayName = `${firstName} ${lastInitial}.`

  const handleSetDropdownState = (): void => {
    setDropdownState(!isDropdownOpen)
  }

  const closeDropdown = (): void => {
    setDropdownState(false)
  }

  const buttonClassName =
    "flex flex-row items-center justify-center bg-slate-900 border-none cursor-pointer p-0"
  const initialsClassName =
    "flex items-center justify-center h-8 w-8 sm:h-14 sm:w-14 rounded-full bg-emerald-300 bdr-teal-800"

  return (
    <div>
      <button
        className={buttonClassName}
        type="button"
        ref={buttonRef}
        data-testid="profile-button"
        onClick={handleSetDropdownState}
      >
        {!isSmallScreenSize && (
          <div className="pr-4">
            <span className="text-xl">
              {`${t("header.profile.hi")}, `}
              <span className="font-bold">{displayName}</span>
            </span>
          </div>
        )}
        <div className="sm:pr-2 lg:pr-2">
          <div className={initialsClassName}>
            <span className="flex text-xs sm:text-xl font-medium text-slate-900">
              {firstInitial}
              {lastInitial}
            </span>
          </div>
        </div>
        <div>
          {!isSmallScreenSize &&
            (isDropdownOpen ? (
              <Icons.UpArrow className="stroke-gray-300" />
            ) : (
              <Icons.DownArrow className="stroke-gray-300" />
            ))}
        </div>
      </button>

      {isDropdownOpen && (
        <Backdrop
          open={isDropdownOpen}
          sx={{
            backdropFilter: "blur(2px)",
            backgroundColor: "#0000004d",
            zIndex: 3,
          }}
        >
          <ProfileDropdown close={closeDropdown} buttonRef={buttonRef} />
        </Backdrop>
      )}
    </div>
  )
}

type ProfileDropdownProps = {
  close: () => void
  buttonRef: RefObject<HTMLButtonElement>
}

const ProfileDropdown = ({
  close,
  buttonRef,
}: ProfileDropdownProps): JSX.Element => {
  const { t } = useTranslation()
  const dropdownRef = useRef<HTMLDivElement>(null)
  UIKit.useClickOutside(() => {
    close()
  }, [dropdownRef, buttonRef])
  const { logout } = useAuth0()

  const handleLogOut = async (): Promise<void> => {
    await logout({
      logoutParams: { returnTo: `${window.location.origin}/#/login` },
    })
  }

  const buttonClassName =
    "flex w-52 items-center justify-start button-zinc-800 border-none space-x-2 px-6"

  return (
    <div
      ref={dropdownRef}
      data-testid="profile-dropdown-container"
      className="absolute z-10 right-5 sm:right-10 self-start mt-28"
    >
      {/* <button
        className={cn(buttonClassName, "rounded-t-3xl pt-6 pb-4")}
        type="button"
      >
        <Icons.SettingsWheel />
        <span className="text-base font-medium">
          {t("header.profile.dropdown.settings")}
        </span>
      </button> */}
      <button
        className={cn(buttonClassName, "rounded-3xl py-6")}
        type="button"
        onClick={handleLogOut}
      >
        <Icons.LogOut />
        <span className="text-base font-medium">
          {t("header.profile.dropdown.log_out")}
        </span>
      </button>
    </div>
  )
}

export default ProfileHeader
