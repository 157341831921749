import { useTranslation } from "react-i18next"
import cn from "classnames"

type ErrorMessageProps = {
  isWideComponent?: boolean
}
const ErrorMessage = ({
  isWideComponent = false,
}: ErrorMessageProps): JSX.Element => {
  const { t } = useTranslation()

  const containerClassName = cn(
    "flex flex-col h-full items-center justify-center text-center px-4",
    { "w-[80%] lg:w-[65%] mx-auto": isWideComponent },
  )
  return (
    <div className={containerClassName}>
      <div className="text-3xl sm:text-5xl text-red-400 pb-6 sm:pb-10 font-medium tracking-tight">
        {t("actionable_data.unable_to_fetch")}
      </div>
      <div className="text-lg leading-8 sm:text-2xl sm:leading-9 font-medium tracking-tighter">
        {t("actionable_data.error_description")}
      </div>
    </div>
  )
}

export default ErrorMessage
