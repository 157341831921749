import { RecentReport } from "@app/model"

export type APIRecentReport = {
  reportId: string
  location: string
  additional_details?: string
  threat: string[]
  mediaUrl: string | null
  createdAt: number
  reportedAt: string
}

export const APIRecentReportsToModel = (
  recentReports: APIRecentReport[],
): RecentReport.RecentReport[] => {
  return recentReports.map(
    ({
      reportId,
      location,
      additional_details,
      threat,
      mediaUrl,
      createdAt,
      reportedAt,
    }) => {
      return {
        reportId,
        location,
        additionalDetails: additional_details,
        threats: threat,
        mediaUrl,
        createdAt,
        reportedAt,
      }
    },
  )
}
