import * as NetworkError from "@app/api/networkError"
import { SingleSignOn } from "@app/model"
import { ParseError } from "@effect/schema/ParseResult"
import * as S from "@effect/schema/Schema"
import { Either, pipe } from "effect"

import { fetchWithoutAuth } from "../api"

export type CheckIsSSOError =
  | NetworkError.NetworkError
  | NetworkError.UnexpectedError
  | ParseError

export const checkIsSSO = async ({
  email,
}: SingleSignOn.CheckIsSSOParams): Promise<SingleSignOn.Result> => {
  const queryParams: SingleSignOn.QueryParams = {
    email,
  }
  const params = new URLSearchParams(queryParams).toString()
  const path = `sts/api/v2/auth/is-sso-configured?${params}`
  const response = await fetchWithoutAuth({
    method: "GET",
    path,
  })

  if (!response.ok) {
    const result = (await response.json()) as unknown

    if (NetworkError.isAPINetworkError(result)) {
      const error = NetworkError.toNetworkError(response.status, result)
      return Either.left(error)
    } else {
      const error = new NetworkError.UnexpectedError(`${result}`)
      return Either.left(error)
    }
  }

  const result = (await response.json()) as unknown

  return pipe(result, S.decodeUnknownEither(SingleSignOn.IsSSOSchema))
}
