import * as S from "@effect/schema/Schema"
import { Either } from "effect"

import { CheckIsSSOError } from "api/account"

export type IsSSO = { is_sso: boolean }

export type QueryParams = {
  email: string
}

export const IsSSOSchema = S.struct({
  is_sso: S.boolean,
})

export type Result = Either.Either<IsSSO, CheckIsSSOError>

export type CheckIsSSOParams = {
  email: string
}
