import React from "react"
import { useTranslation } from "react-i18next"

const LiveAgentActivities = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className="flex lg:w-full min-h-72 lg:h-full lg:min-h-[19rem] px-6 py-8 visualization">
      <span className="visualization-title">
        {t("performance_analytics.live_agent_activities")}
      </span>
    </div>
  )
}

export default LiveAgentActivities
