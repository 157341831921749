import React from "react"
import ReactDOM from "react-dom/client"

import "./i18n/i18n"

import App from "./App"

const element = document.getElementById("root")

if (element) {
  const root = ReactDOM.createRoot(element)
  root.render(<App />)
}
