import React, { createContext, ReactNode, useState } from "react"
import { DashboardTab } from "@app/model/dashboardTab"

interface ActiveTabContextType {
  activeTab: DashboardTab
  updateActiveTab: (tab: DashboardTab) => void
}

export const ActiveTabContext = createContext<ActiveTabContextType>({
  activeTab: DashboardTab.ActionableData,
  updateActiveTab: () => {},
})

type ActiveTabProviderProps = {
  children: ReactNode
}

export const ActiveTabProvider = ({
  children,
}: ActiveTabProviderProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<DashboardTab>(
    DashboardTab.ActionableData,
  )

  const updateActiveTab = (tab: DashboardTab): void => {
    setActiveTab(tab)
  }

  return (
    <ActiveTabContext.Provider value={{ activeTab, updateActiveTab }}>
      {children}
    </ActiveTabContext.Provider>
  )
}

export const useActiveTabContext = (): ActiveTabContextType => {
  const context = React.useContext(ActiveTabContext)
  if (context === undefined) {
    throw new Error(
      "useActiveTabContext must be used within a ActiveTabProvider",
    )
  }
  return context
}
